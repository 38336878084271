<template>
  <div class="charecteristc main-block">
    <div class="main-block__head">
      <h1 class="main-block__title">Статистика</h1>
    </div>
    <div class="charecteristc__block row">
      <div class="col">
        <div class="stats">
          <span class="dot orange"></span> <span class="count">{{ this.regular_repairs }}</span> <br> <br>
          <div class="flex">
            <span class="title">Регулярное обслуживание</span> <span class="percent">{{ this.total_count ? Number((this.regular_repairs/this.total_count*100).toFixed(this.toFixed)) : 0 }} %</span>
          </div>
        </div>
        <div class="stats">
          <span class="dot green"></span> <span class="count">{{ this.necessary_repairs }}</span> <br> <br>
          <div class="flex">
            <span class="title">Необходимый ремонт</span> <span class="percent">{{ this.total_count ? Number((this.necessary_repairs/this.total_count*100).toFixed(this.toFixed)) : 0 }} %</span>
          </div>
        </div>
        <div class="stats">
          <span class="dot blue"></span> <span class="count">{{ this.unexpected_repairs }}</span> <br> <br>
          <div class="flex">
            <span class="title">Непредвиденный ремонт</span> <span class="percent">{{ this.total_count ? Number((this.unexpected_repairs/this.total_count*100).toFixed(this.toFixed)) : 0 }} %</span>
          </div>
        </div>
      </div>
      <div class="col">
        <apexchart type="donut" :options="options" :series="series" />
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  name: "Cost",
  title: "Transport costs",
  props: {
    allRepairs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      toFixed: 0,
      regular_repairs: 0,
      necessary_repairs: 0,
      unexpected_repairs: 0,
    };
  },
  computed: {
    series() {
      return [
        this.unexpected_repairs, this.necessary_repairs, this.regular_repairs,
      ];
    },
    options() {
      return {
        labels: ['Непредвиденный ремонт', 'Необходимый ремонт', 'Регулярное обслуживание',],
        chart: {
          type: 'donut'
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return Number((val).toFixed(0)) + "%"
          },
          style: {}
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            customScale: 0.8,
            donut: {
              size: '90%',
              labels: {
                show: true,
                name: {

                },
                value: {

                },
                total: {
                  show: true,
                  label: "Общее кол-во",
                  color: '#000000',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }
                }
              }
            }
          }
        }
      }
    },
    total_count() {
      return this.regular_repairs + this.necessary_repairs + this.unexpected_repairs
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    thousandsWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getData() {
      this.$api
          .get("/web/repair/statistic/")
          .then((response) => {
            this.regular_repairs = response.data.regular_repairs
            this.necessary_repairs = response.data.necessary_repairs
            this.unexpected_repairs = response.data.unexpected_repairs
          })
          .catch(() => {
            this.$toast.error('Что-то пошло не так, попробуйте позднее!');
          });
    },
  }
};
</script>

<style scoped>
.charecteristc__block {
  align-items: flex-start;
}
.dot {
  width: 15px;
  height: 15px;
  border-radius: 1px;
  display: inline-block;
}
.green {
  background: rgb(0, 227, 150);
}
.orange {
  background: rgb(254, 176, 25);
}
.blue {
  background: rgb(0, 143, 251)
}
.title {
  margin-left: 18px;
  font-weight: 400;
}
.count {
  font-size: 20px;
}
.stats {
  margin: 30px 0 0 30px;
  font-size: 14px;
}
.percent {
  color: #ACACAC
}
</style>